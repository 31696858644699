import './LandingPage.css';
import bitterroot from '../images/bitterroot.jpg';
import { CheckIcon } from '@heroicons/react/20/solid'
import Header from '../components/Header';
import Footer from '../components/Footer';

const features = [
    {
        "name": "Medical Equipment Sales",
        "description": "Sold various emerging medical equipment and devices since 2008."
    },
    {
        "name": "Cyber Security Systems",
        "description": "Provided cyber security systems to US NAVY and Department of Energy."
    },
    {
        "name": "University Research Support",
        "description": "Supported research activities at the University of Montana."
    },
    {
        "name": "Veteran Trauma Research",
        "description": "Participated in traumatic injury research for veterans."
    },
    {
        "name": "Research Consulting",
        "description": "Consulted on projects like weather observations and fiber optic sensors."
    },
    {
        "name": "Military Training Support",
        "description": "Participated in military training events, specializing in Red Team operations."
    },
    {
        "name": "Interactive Fitness Equipment",
        "description": "Sold interactive fitness equipment like Expresso Bikes."
    },
    {
        "name": "Medical Simulation Devices",
        "description": "Sold medical simulation devices such as Simbionix simulators."
    }
]


export default function LandingPage() {

    return (
        <div className="bg-white">

            {/* HEADER */}
            <Header />

            {/* HERO */}
            <div className="relative">
                <div className="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8 min-h-screen items-center">
                    <div className="px-6 pb-24 pt-10 sm:pb-32 lg:col-span-7 lg:px-0 lg:pb-56 lg:pt-48 xl:col-span-6">
                        <div className="mx-auto max-w-2xl lg:mx-0">
                            <h4 className="mt-24 font-bold tracking-tight text-gray-900 sm:mt-10 text-2xl uppercase">
                                Bitterroot Services & Technology
                            </h4>

                            <h1 className="mt-24 text-4xl font-bold tracking-tight text-gray-900 sm:mt-10">
                                Integrating top-tier expertise with veteran leadership
                            </h1>
                            <p className="mt-6 text-lg leading-8 text-gray-600">
                                Bitterroot Services & Technology provides unmatched expertise in R&D, human factors, and training support for military, corporate, and government clients.
                            </p>
                            <div className="mt-10 flex items-center gap-x-6">
                                <a
                                    href="#contact"
                                    className="rounded-md bg-sky-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                                >
                                    Contact us
                                </a>
                                <a href="/about" className="text-sm font-semibold leading-6 text-gray-900">
                                    About us <span aria-hidden="true">→</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="relative lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 xl:mr-0">
                        <img
                            className="aspect-[3/2] w-full bg-gray-50 object-cover lg:absolute lg:inset-0 lg:aspect-auto lg:h-full"
                            src={bitterroot}
                            alt=""
                        />
                    </div>
                </div>
            </div>

            {/* CONTENT / PARAGRAPH  */}
            <div className="relative isolate overflow-hidden bg-white py-24 sm:py-32">
                <div
                    className="absolute -top-80 left-[max(6rem,33%)] -z-10 transform-gpu blur-3xl sm:left-1/2 md:top-20 lg:ml-20 xl:top-3 xl:ml-56"
                    aria-hidden="true"
                >
                    <div
                        className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#38bdf8] to-[#0c4a6e] opacity-30"
                        style={{
                            clipPath:
                                'polygon(63.1% 29.6%, 100% 17.2%, 76.7% 3.1%, 48.4% 0.1%, 44.6% 4.8%, 54.5% 25.4%, 59.8% 49.1%, 55.3% 57.9%, 44.5% 57.3%, 27.8% 48%, 35.1% 81.6%, 0% 97.8%, 39.3% 100%, 35.3% 81.5%, 97.2% 52.8%, 63.1% 29.6%)',
                        }}
                    />
                </div>
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:mx-0">
                        <p className="text-lg font-semibold leading-8 tracking-tight text-sky-600">Learn about our services</p>
                        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Expertise & capabilites</h1>
                        <p className="mt-6 text-xl leading-8 text-gray-700">
                            Bitterroot Services & Technology LLC (BST), is a Service Disabled Veteran Owned Small Business (SDVOSB). BST integrates and synchronizes Research and Development, Human Factors Engineering and Training Support, for our Military, Corporate, and Government clientele.
                        </p>
                        <p className="mt-6 text-xl leading-8 text-gray-700">
                            BST combines over 100 years of Consultancy and Professional Services with a human resource reach-back into a pool of highly trained professionals. Our core competencies originate from a consortium of PhDs, an Unattended Ground Sensor expert and the president is a retired and decorated 20-year Air Force Special Operations Officer.
                        </p>
                    </div>
                    <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:mt-10 lg:max-w-none lg:grid-cols-12">
                        <div className="relative lg:order-last lg:col-span-5">
                            <svg
                                className="absolute -top-[40rem] left-1 -z-10 h-[64rem] w-[175.5rem] -translate-x-1/2 stroke-gray-900/10 [mask-image:radial-gradient(64rem_64rem_at_111.5rem_0%,white,transparent)]"
                                aria-hidden="true"
                            >
                                <defs>
                                    <pattern
                                        id="e87443c8-56e4-4c20-9111-55b82fa704e3"
                                        width={200}
                                        height={200}
                                        patternUnits="userSpaceOnUse"
                                    >
                                        <path d="M0.5 0V200M200 0.5L0 0.499983" />
                                    </pattern>
                                </defs>
                                <rect width="100%" height="100%" strokeWidth={0} fill="url(#e87443c8-56e4-4c20-9111-55b82fa704e3)" />
                            </svg>
                            <figure className="border-l border-sky-600 pl-8">
                                <blockquote className="text-xl font-semibold leading-8 tracking-tight text-gray-900">
                                    <p>
                                        “Our unwavering dedication to excellence, combined with unmatched expertise and veteran leadership, guarantees a successful outcome for our clients every time.”
                                    </p>
                                </blockquote>
                                <figcaption className="mt-8 flex gap-x-4">
                                    <div className="text-sm leading-6">
                                        <div className="font-semibold text-gray-900">Bitterroot Services & Technology Team</div>
                                    </div>
                                </figcaption>
                            </figure>
                        </div>
                        <div className="max-w-xl text-base leading-7 text-gray-700 lg:col-span-7">
                            <p>
                                At BST, we are dedicated to delivering tailored solutions that meet the unique needs of our Military, Corporate,
                                and Government clientele. With our extensive experience and specialized expertise, we provide unparalleled
                                Research and Development capabilities, cutting-edge Human Factors Engineering, and comprehensive Training
                                Support. Our team of seasoned veterans and distinguished professionals ensures that each project is executed
                                with precision and excellence.
                            </p>

                            <p className="mt-8">
                                Our commitment to quality and innovation drives us to continually improve and expand our services. At BST,
                                we understand that every client is unique, and we tailor our solutions to meet your specific needs. Whether
                                you require advanced R&D, expert training programs, or specialized engineering support, we have the
                                expertise and resources to deliver exceptional results.
                            </p>

                        </div>
                    </div>
                </div>
            </div>

            {/* PROJECTS SECTION */}
            <div className="bg-white py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                        <div>
                            <h2 className="text-base font-semibold leading-7 text-sky-600">Seasoned government contracting experts</h2>
                            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Extensive experience</p>
                            <p className="mt-6 text-base leading-7 text-gray-600">
                                BST’s experience highlights seasoned expertise in government contracts, encompassing medical equipment sales, cyber security system provisioning, university research support, veteran trauma research, scientific project consulting, and military training support.
                            </p>
                        </div>
                        <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:gap-y-16">
                            {features.map((feature) => (
                                <div key={feature.name} className="relative pl-9">
                                    <dt className="font-semibold text-gray-900">
                                        <CheckIcon aria-hidden="true" className="absolute left-0 top-1 h-5 w-5 text-sky-600" />
                                        {feature.name}
                                    </dt>
                                    <dd className="mt-2">{feature.description}</dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>
            </div>

            {/* CONTACT SECTION  */}
            <div id="contact" className="bg-white py-16 sm:py-24">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl space-y-16 divide-y divide-gray-100 lg:mx-0 lg:max-w-none">
                        <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3">
                            <div>
                                <h2 className="text-3xl font-bold tracking-tight text-gray-900">Get in touch</h2>
                                <p className="mt-4 leading-7 text-gray-600">
                                    We'd love to hear about your project requirements and discuss how we can help.
                                </p>
                            </div>
                            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8">
                                <div className="rounded-2xl bg-gray-50 p-10">
                                    <h3 className="text-base font-semibold leading-7 text-gray-900">Email</h3>
                                    <dl className="mt-3 space-y-1 text-sm leading-6 text-gray-600">
                                        <div>
                                            <dt className="sr-only">Email</dt>
                                            <dd>
                                                <a className="font-semibold text-sky-600" href="mailto:collaborate@example.com">
                                                    tmaki@bitterroot.us
                                                </a>
                                            </dd>
                                        </div>
                                    </dl>
                                </div>
                                <div className="rounded-2xl bg-gray-50 p-10">
                                    <h3 className="text-base font-semibold leading-7 text-gray-900">Phone</h3>
                                    <dl className="mt-3 space-y-1 text-sm leading-6 text-gray-600">
                                        <div>
                                            <dt className="sr-only">Phone</dt>
                                            <dd>
                                                <a className="font-semibold text-sky-600" href="mailto:hello@example.com">
                                                    (406) 241-1708
                                                </a>
                                            </dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* FOOTER */}
            <Footer />
        </div>
    )
}
